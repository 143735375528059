import React, { useState, useEffect } from 'react';
import { Link,useNavigate } from "react-router-dom";
import Loader from "./../front/Loader";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Footer from "./Footer";
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import moment from 'moment';
import axios from 'axios';
import Swal from 'sweetalert2'
import { API_URL, API_KEY, API_TOKEN } from './../../config/constant';
const config = {
    headers: { Authorization: `Bearer ${API_TOKEN}` }
};

toast.configure()
export default function OrderHistory() {
    document.title = "BevPort - Sales Order"
    const [toggle, setToggle] = useState(window.innerWidth <= 993 ? true : false);
    const [submitLoader, setSubmitLoader] = useState(true);
    const [activeTab, setActiveTab] = useState("all")
    const collapseSidebar = () => {
        setToggle(!toggle)
    }
    const navigate = useNavigate();

    const columns = [
        {
            name: 'Order ID',
            selector: row => row.orderId,
            cell: row => row.orderId,
            width: '190px',
            sortable: true,
        },
        {
            name: 'Buyer Name',
            selector: row => row.buyerName,
            sortable: true,
        },
        {
            name: 'Quantity In Case',
            selector: row => row.totalQuantity,
            sortable: true,
        },
        {
            name: 'Total Amount',
            selector: row => parseFloat(row.finalPrice),
            cell: row => "$" + parseFloat(row.finalPrice).toFixed(2),
            sortable: true,
        },
        {
            name: 'Order Date',
            selector: row => (row.createdDate) ? moment(row.createdDate) : 0,
            cell: row => row.createdDate ? moment(row.createdDate).format('MM-DD-YYYY hh:mm A') : "-",
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row.status,
            // cell: row => <span style={{ "color": "#00aeef" }}>S.O. Created</span>,
            cell: row => (row.status == 0 || row.status == 1 || row.status == 2  ) ? <span style={{ "color": "#00aeef" }}>Placed</span> :( row.status == 3 ) ? <span style={{ "color": "#00aeef" }}>Order on Dock</span>:( row.status == 4)?<span style={{ "color": "#00aeef" }}>Order Left Warehouse</span>:(row.status == 5)?<span style={{ "color": "#00aeef" }}>Order Staged at SSD</span>:(row.status == 6)?<span style={{ "color": "#00aeef" }}>Order Out for Delivery</span>:(row.status == 7)?<span style={{ "color": "#00aeef" }}>Order Delivered</span>:(row.status == 20)?<span style={{ "color": "#00aeef" }}>Rejected</span>:"",
            sortable: true
        },
        {
            name: 'Action',
            width: '250px',
            selector: row =>
                <><Link className="btn btn-primary" to={(row.status != 20)?"/admin/order-detail/" + row.orderId:"#"} target={(row.status != 20)?'_blank':''}>View</Link>&nbsp;<Link className="btn btn-primary" to={(row.status == 1 || row.status == 0)?"/admin/create-packlist/"+row.orderId:""} onClick={() => craetePickList(row.orderId, row.status)} disabled={(row.status == 2 || row.status == 20) ? true : false}>Create Pack List</Link></>,
        }
    ];
    const [data, setData] = useState([])
    useEffect(() => {
        getOrderHistory()
    }, [])
    const getOrderHistory = async () => {
        await axios.post(API_URL + "GetAllOrderList?code=" + API_KEY, {}, config).then((res) => {

            if (res.data.responseCode == "200") {
                setData(res.data.responseData)

            } else {
                setData([])
            }
            setSubmitLoader(false);
        }).catch(function (error) {
            setSubmitLoader(false);
        })
    }
    const tableData = {
        columns,
        data
    };
    const craetePickList = async (orderId, status) => {
        if (status == 1 || status == 0) {
            // Swal.fire({
            //     title: 'Do you want to create pack list?',
            //     showDenyButton: false,
            //     showCancelButton: true,
            //     confirmButtonText: 'Yes',
            //     cancelButtonText: 'No',
            //     confirmButtonColor: '#205380',
            //     denyButtonColor: '#205380',
            //     background: '#fff',
            //     color: '#000',
            // }).then(async (result) => {
            //     if (result.isConfirmed) {
            //         setSubmitLoader(true);
            //         await axios.post(API_URL + "PicklistInsert?code=" + API_KEY, { "OrderID": orderId }, config).then((res) => {
            //             if (res.data.responseCode == "200") {
            //                 Swal.fire({
            //                     icon: 'success',
            //                     title: '',
            //                     text: res.data?.responseMessage,
            //                     confirmButtonColor: '#205380',
            //                 }).then((result)=>{
            //                     if(result.isConfirmed){
            //                         navigate("/admin/order-pick-list");   
            //                     }
            //                 })
            //                 getOrderHistory()
            //             } else {
            //                 Swal.fire({
            //                     icon: 'error',
            //                     title: '',
            //                     text: res.data?.responseMessage,
            //                     confirmButtonColor: '#205380',
            //                 })
            //             }
            //             setSubmitLoader(false);
            //         }).catch(function (error) {
            //             setSubmitLoader(false);
            //         })
            //     }
            // })
        }

    }
    return (
        <>

            <div className="mainAdminWrapper">
                <Sidebar collapse={toggle} />
                <div className={!toggle ? (submitLoader ? "wholeCompoundRight vissibleHide" : "wholeCompoundRight") : "wholeCompoundRight fullwidth"}>
                    {submitLoader ? <Loader /> : ""}
                    <Header collapseSidebar={collapseSidebar} />
                    <div className="rightContetBlock">
                        <div className="headingTop">
                            <h1>Sales Order</h1>
                        </div>
                        <div className="blank150"></div>
                        <div className="fixtopContentBg">
                            <div className="card-Content">
                                <div className="Content-body dataTableSearchBox">
                                    <DataTableExtensions {...tableData} export={false} print={false} filterPlaceholder="Search Orders">
                                        <DataTable
                                            columns={columns}
                                            data={data}
                                            defaultSortAsc={false}
                                            defaultSortFieldId={5}
                                            noDataComponent={<span className='norecordFound'>No record found!</span>}
                                        />
                                    </DataTableExtensions>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}