import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN, BASE_URL } from './../../config/constant';
import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams } from "react-router-dom";
import Loader from "./../front/Loader";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Footer from "./Footer";
import LogoInvoice from './../../assets/images/logo.png'
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactToPrint from "react-to-print";
import QRCode from "qrcode.react";
import moment from 'moment';
const config = {
    headers: { Authorization: `Bearer ${API_TOKEN}` }
};

toast.configure()
function OrderDetail() {
    const [toggle, setToggle] = useState(window.innerWidth <= 993 ? true : false)
    const collapseSidebar = () => {
        setToggle(!toggle)
    }
    let { orderID } = useParams();
    const [data, setData] = useState([]);
    const [submitLoader, setSubmitLoader] = useState(true);
    const [encyptedOrderID, setEncryptedOrderID] = useState("");
    document.title = "#" + orderID
    let componentRef = useRef();
    useEffect(() => {
        getOrderDetail();
    }, [])

    const getOrderDetail = async () => {
        await axios.post(API_URL + "GetOrderDetails?code=" + API_KEY, { OrderID: orderID }, config).then((res) => {
            if (res.data.responseCode == "200") {
                setData(res.data.responseData);
                setEncryptedOrderID(res.data.responseData?.[0]?.encryptOrderID);
            } else {
                setData([])
            }
            setSubmitLoader(false);
        }).catch(function (error) {
            setSubmitLoader(false);
        })
    }

    var QRValue = BASE_URL+"/view-invoice/"+encyptedOrderID+"/"+localStorage.getItem('id');

    return (
        <>

            <div className="mainAdminWrapper">
                <Sidebar collapse={toggle} />
                <div className={!toggle ? (submitLoader ? "wholeCompoundRight vissibleHide" : "wholeCompoundRight") : "wholeCompoundRight fullwidth"}>
                    {submitLoader ? <Loader /> : ""}
                    <Header collapseSidebar={collapseSidebar} />
                    <div className="rightContetBlock">
                        <div className="headingTop">
                            <h1>Purchase Order</h1>
                        </div>
                        <div className="blank150"></div>
                        <div className="fixtopContentBg">
                            <div className="card-Content">
                                <div className="invoicePagesWrapper">
                                    <div className="wizardShowOnly">
                                        <div className="wizardShowOnly" ref={(el) => (componentRef = el)}>
                                            <div className="row invoiceHeader">
                                                <div className="col-md-6">
                                                    <div className="LogoInvoice">
                                                        <img src={LogoInvoice} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="InvoiceTextHead">
                                                        <h3>Purchase Order</h3>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row orderHeader">
                                                <div className="col-md-6">
                                                    <div className="helloAreas">
                                                        <p>Hello {data?.[0]?.buyerName},</p>
                                                        <p>Thank you for shopping with Bevport. Your order is on the way!</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="orderIdText">
                                                        <p>PURCHASE ORDER ID: <span className="orderNumbersId">#{data?.[0]?.orderID}</span></p>
                                                        <p>{moment(data?.[0]?.createdDate).format('MM-DD-YYYY hh:mm A')}</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row informationBiligns">
                                                <div className="col-md-6">
                                                    <div className="billingImforArea">
                                                        <h3>BILLING ADDRESS</h3>
                                                        <div className="callAdress samllwidth">
                                                        <p>{data?.[0]?.buyerBusinessName}</p>
                                                            <p>{data?.[0]?.billingStreet} <span className='showblock'>{data?.[0]?.billingCityName}, {data?.[0]?.billingStateName} {data?.[0]?.billingZipCode}</span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="billingImforArea">
                                                        <h3>PAYMENT MODE</h3>
                                                        <div className="callAdress">
                                                        <p>{(data?.[0]?.buyerPaymentType == 1)? "Fintech" :"Check(COD)"}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="billingImforArea qr-code">
                                                        {/*<QRCode value={QRValue} />*/}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row informationBiligns Shippinhind">
                                                <div className="col-md-6">
                                                    <div className="billingImforArea">
                                                        <h3>DELIVERY ADDRESS</h3>
                                                        <div className="callAdress samllwidth">
                                                        <p>{data?.[0]?.buyerBusinessName}</p>
                                                            <p>{data?.[0]?.buyerAddress} <span className='showblock'>{data?.[0]?.cityName}, {data?.[0]?.stateName} {data?.[0]?.zipCode}</span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="billingImforArea">
                                                        <h3>DELIVERY METHOD</h3>
                                                        <div className="callAdress">
                                                            <p>3 Mavins Distribution</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row puchaseHistoryTable">
                                                <div className="col-md-12">
                                                    <table className="table table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col" width="40%">Item</th>
                                                                <th className="brewerNames" scope="col" >Brand Name</th>
                                                                <th className="qtyTextr" scope="col" width="20%">Cases</th>
                                                                <th className="amountDallrs" scope="col" width="20%">$ Amount</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {data.map((_v, _i) => {
                                                                return [
                                                                    <tr>
                                                                        <td>
                                                                            <span className="mainPrName">{_v?.productName}</span>
                                                                            <span className="brandNameSm">{_v?.breweryName}</span>
                                                                        </td>
                                                                        <td className="brewerNames">{_v?.brandName}</td>
                                                                        <td className="qtyTextr">{_v?.quantity}</td>
                                                                        <td className="amountDallrs">{parseFloat(_v?.amount).toFixed(2)}</td>
                                                                    </tr>
                                                                ]
                                                            })}


                                                            <tr>
                                                                <td></td>
                                                                <td></td>
                                                                <td className="qtyTextr grandtotals">Grand Total</td>
                                                                <td className="amountDallrs finalPrice">{parseFloat(data?.[0]?.finalPrice).toFixed(2)}</td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row Prinatblebtn">
                                            <div className="col-md-12">
                                                <div className="formBtns">
                                                    <ReactToPrint
                                                        trigger={() => <button type="button" className="btn btn-secondary save-button"><i className="fa fa-print"></i> Print</button>}
                                                        content={() => componentRef}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}

export default OrderDetail