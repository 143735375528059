
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import aboutbgd from "./../../assets/images/aboutbgd.png"
import resourcesbg from "./../../assets/images/resources-pagebg.jpg"
import Header from './Header';
import Footer from './Footer';
import Select from 'react-select';
import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN } from './../../config/constant.js';
import Tooltip from "@mui/material/Tooltip";
import HelpIcon from '@mui/icons-material/Help';
const config = {
	headers: { Authorization: `Bearer ${API_TOKEN}` }
};
export default function Resource() {
	document.title = "BevPort - Resources"
	useEffect(() => {
		const script = document.getElementById('recaptcha-key');
	    if (script) {
	      	script.remove();
	    }
	    const recaptchaElems = document.getElementsByClassName('grecaptcha-badge');
	    if (recaptchaElems.length) {
	      	recaptchaElems[0].remove();
	    }
	},[])
	const [packSize, setPackSize] = useState(6)
	const [caseSize, setCaseSize] = useState(24)
	/*const [stateCaseTax, setStateCaseTax] = useState(1.08)
	const [statePackTax, setStatePackTax] = useState(0)
	const [stateUnitTax, setStateUnitTax] = useState(0)*/
	const [stateCaseTax, setStateCaseTax] = useState(0.00)
	const [statePackTax, setStatePackTax] = useState(0)
	const [stateUnitTax, setStateUnitTax] = useState(0)
	const [amountPer, setAmountPer] = useState("case")
	const [type, setType] = useState("FOB")
	const [amountIn, setAmountIn] = useState(0)
	const [amountInTmp, setAmountInTmp] = useState(0)
	const [amountError, setAmountError] = useState(false)
	const [unitCasePrice, setUnitCasePrice] = useState(0)
	const [packPrice, setPackPrice] = useState(0)
	const [unitPrice, setUnitPrice] = useState(0)
	/*const [cmcDeliveryFeeCase, setCmcDeliveryFeeCase] = useState(3.50)
	const [cmcDeliveryFeePack, setCmcDeliveryFeePack] = useState(0)
	const [cmcDeliveryFeeUnit, setCmcDeliveryFeeUnit] = useState(0)*/
	const [cmcDeliveryFeeCase, setCmcDeliveryFeeCase] = useState(0.00)
	const [cmcDeliveryFeePack, setCmcDeliveryFeePack] = useState(0)
	const [cmcDeliveryFeeUnit, setCmcDeliveryFeeUnit] = useState(0)
	const [bevDeliveryFeeCase, setBevDeliveryFeeCase] = useState(10.00) // this needs to go into a master config file, change in 1 place and its changes it every where for delivery fee
	const [bevDeliveryFeePack, setBevDeliveryFeePack] = useState(0)
	const [bevDeliveryFeeUnit, setBevDeliveryFeeUnit] = useState(0)
	const [bpMargin, setBPMargin] = useState(10.00)
	const [avMargin, setAVMargin] = useState(28)
	const [caseListingPrice, setCaseListingPrice] = useState(0)
	const [packListingPrice, setPackListingPrice] = useState(0)
	const [unitListingPrice, setUnitListingPrice] = useState(0)
	const [caseSRP, setCaseSRP] = useState(0)
	const [packSRP, setPackSRP] = useState(0)
	const [unitSRP, setUnitSRP] = useState(0)
	const [caseBPPrice, setCaseBPPrice] = useState(0)
	const [packBPPrice, setPackBPPrice] = useState(0)
	const [unitBPPrice, setUnitBPPrice] = useState(0)
	const [listPricemanual, setListPricemanual] = useState(0)
	const [unitPriceList, setUnitPriceList] = useState(0)
	const [packPriceList, setPackPriceList] = useState(0)
	const [casePriceList, setCasePriceList] = useState(0)
	const [valueUpd, setValueUpd] = useState(false)
	const [caseAmountError, setCaseAmountError] = useState(false)
	const [packAmountError, setPackAmountError] = useState(false)
	const [unitAmountError, setUnitAmountError] = useState(false)
	const [cmcDeliveryFeePackEdit, setCmcDeliveryFeePackEdit] = useState(true)
	const [cmcDeliveryFeeUnitEdit, setCmcDeliveryFeeUnitEdit] = useState(true)
	const [stateTaxPackEdit, setStateTaxPackEdit] = useState(true)
	const [stateTaxUnitEdit, setStateTaxUnitEdit] = useState(true)
	const [packSizeList, setPackSizeList] = useState([]);
	const [caseSizeList, setCaseSizeList] = useState([]);
	const [caseSizeValue, setCaseSizeValue] = useState({ value: "24 Unit", label: "24 Unit" });
	const [packSizeValue, setPackSizeValue] = useState({ value: "6 Packs", label: "6 Packs" });
	const calculateListingPrice = (unitCasePrice, cmcDeliveryFeeCase, bevDeliveryFeeCase) => {
		let g27 = parseFloat(unitCasePrice) + parseFloat(cmcDeliveryFeeCase);
		let g28 = parseFloat(bpMargin) / 100;
		return g27 * (1 + parseFloat(g28)) + bevDeliveryFeeCase;
	}
	const srpCalculate = (g29) => {
		//return g29 * (1 + parseFloat(avMargin) / 100)
		return g29 / (1 - parseFloat(avMargin) / 100)
	}

	useEffect(() => {
		//alert(amountInTmp);

		if (packSize || caseSize) {
			//setPackSize(packSizeD)
			//setCaseSize(caseSizeD)
			//setCanSize(canSize);

			// amountInTmp ---> is alwys the value in the price calculator text box
			let div = caseSize / packSize;
			setAmountIn(amountInTmp * div);
		}
	}, [packSize, caseSize])


	useEffect(() => {
		let amountIntmp = (amountIn && amountIn != ".") ? amountIn : 0
		let cmcDeliveryFeeCaseTmp = cmcDeliveryFeeCase?cmcDeliveryFeeCase:0;
		let stateCaseTaxTmp = stateCaseTax?stateCaseTax:0;
		//if(amountIn && amountIn != "."){
		if (type === "FOB") {
			setUnitCasePrice(amountIntmp)
			setBPMargin(10.00)
			setAVMargin(28)
			let packByDevide = caseSize / packSize
			setPackPrice(amountIntmp / packByDevide)
			setUnitPrice(amountIntmp / caseSize)
			if(stateTaxPackEdit){
				setStatePackTax(stateCaseTaxTmp?priceConverter(stateCaseTaxTmp / packByDevide):0)
			}
			if(stateTaxUnitEdit){
				setStateUnitTax(stateCaseTaxTmp?priceConverter(stateCaseTaxTmp / caseSize):0)
			}
			if(cmcDeliveryFeePackEdit){
				setCmcDeliveryFeePack(cmcDeliveryFeeCaseTmp?priceConverter(cmcDeliveryFeeCaseTmp / packByDevide):0)
			}
			if(cmcDeliveryFeeUnitEdit){
				setCmcDeliveryFeeUnit(cmcDeliveryFeeCaseTmp?priceConverter(cmcDeliveryFeeCaseTmp / caseSize):0)
			}
			setBevDeliveryFeePack(bevDeliveryFeeCase / packByDevide)
			setBevDeliveryFeeUnit(bevDeliveryFeeCase / caseSize)
			//let caseLP = calculateListingPrice(amountIntmp, cmcDeliveryFeeCaseTmp, bevDeliveryFeeCase)
			let caseLP = priceConverter((parseFloat(amountIntmp) + parseFloat(cmcDeliveryFeeCaseTmp) + parseFloat(stateCaseTaxTmp)))
			//let caseLPCalculate = ((parseFloat(caseLP)+bevDeliveryFeeCase)/(1-0.10))
			let caseLPCalculate = ((parseFloat(caseLP))/(1-0.10))+bevDeliveryFeeCase
			setCaseListingPrice(priceConverter(caseLPCalculate))
			//let packLP = calculateListingPrice((amountIntmp / packByDevide), (cmcDeliveryFeeCaseTmp / packByDevide), (bevDeliveryFeeCase / packByDevide))
			let packLP = priceConverter(parseFloat(amountIntmp / packByDevide) + parseFloat(cmcDeliveryFeeCaseTmp / packByDevide) + parseFloat(stateCaseTaxTmp / packByDevide))
			let packLPCalculate = ((parseFloat(packLP))/(1-0.10))+ parseFloat(bevDeliveryFeeCase / packByDevide)
			setPackListingPrice(priceConverter(packLPCalculate))
			//let unitLP = calculateListingPrice((amountIntmp / caseSize), (cmcDeliveryFeeCaseTmp / caseSize), (bevDeliveryFeeCase / caseSize))
			let unitLP = priceConverter(parseFloat(amountIntmp / caseSize) + parseFloat(cmcDeliveryFeeCaseTmp / caseSize) + parseFloat(stateCaseTaxTmp / caseSize))
			let unitLPCalculate = ((parseFloat(unitLP))/(1-0.10))+ parseFloat(bevDeliveryFeeCase / caseSize)
			setUnitListingPrice(priceConverter(unitLPCalculate))
			/*setCaseSRP(srpCalculate(caseLP))
			setPackSRP(srpCalculate(packLP))
			setUnitSRP(srpCalculate(unitLP))*/
			setCaseSRP(srpCalculate(caseLPCalculate))
			setPackSRP(srpCalculate(packLPCalculate))
			setUnitSRP(srpCalculate(unitLPCalculate))
			setCaseBPPrice(priceConverter(parseFloat(amountIntmp) + parseFloat(cmcDeliveryFeeCaseTmp) + parseFloat(stateCaseTaxTmp)))
			setPackBPPrice(priceConverter(parseFloat(amountIntmp / packByDevide) + parseFloat(cmcDeliveryFeeCaseTmp / packByDevide) + parseFloat(stateCaseTaxTmp / packByDevide)))
			setUnitBPPrice(priceConverter(parseFloat(amountIntmp / caseSize) + parseFloat(cmcDeliveryFeeCaseTmp / caseSize) + parseFloat(stateCaseTaxTmp / caseSize)))
		} else if (type === "SRP") {
			setUnitCasePrice(amountIntmp)
			let packByDevide = caseSize / packSize
			setPackPrice(amountIntmp / packByDevide)
			setUnitPrice(amountIntmp / caseSize)
			setBPMargin(10.00)
			setAVMargin(28)
			if(stateTaxPackEdit){
				setStatePackTax(stateCaseTaxTmp?priceConverter(stateCaseTaxTmp / packByDevide):0)
			}
			if(stateTaxUnitEdit){
				setStateUnitTax(stateCaseTaxTmp?priceConverter(stateCaseTaxTmp / caseSize):0)
			}
			if(cmcDeliveryFeePackEdit){
				setCmcDeliveryFeePack(cmcDeliveryFeeCaseTmp?priceConverter(cmcDeliveryFeeCaseTmp / packByDevide):0)
			}
			if(cmcDeliveryFeeUnitEdit){
				setCmcDeliveryFeeUnit(cmcDeliveryFeeCaseTmp?priceConverter(cmcDeliveryFeeCaseTmp / caseSize):0)
			}
			setBevDeliveryFeePack(bevDeliveryFeeCase / packByDevide)
			setBevDeliveryFeeUnit(bevDeliveryFeeCase / caseSize)
			let bpCaseLP = amountIntmp * (1 - .28)
			let bpPackLP = (amountIntmp / packByDevide) * (1 - .28)
			let bpUnitLP = (amountIntmp / caseSize) * (1 - .28)


			//alert(amountIn )

			// 24 Unit - Bevport Listing Price
			// --------------------- 24 unit case Bevport Listing Price ------------------------------------------------
			var a1 = avMargin / 100;
			var BevportListingPrice_24Unit = amountIn * (1 - a1);
			setCaseListingPrice(BevportListingPrice_24Unit);
			//----------------------------------------------------------------------------------------------------------

			// 4 pack -  Bevport Listing Price -------------------------------------------------------------------------
			var a2 = avMargin / 100;
			var FourPackPrice = amountIn / 6;
			var BevportListingPrice_4Pack = FourPackPrice * (1 - a2);
			setPackListingPrice(BevportListingPrice_4Pack);
			//-----------------------------------------------------------------------------------------------------------

			// Unit - Bevport Listing Price-------------------------------------------------------------------------------
			var a3 = avMargin / 100;
			var pUnitPrice = amountIn / 24;
			var BevportListingPrice_Unit = pUnitPrice * (1 - a3);
			setUnitListingPrice(BevportListingPrice_Unit);
			//------------------------------------------------------------------------------------------------------------


			// Just Bevport Price
			// ----------------- 24 Case - Bevport Price ----------------------------------------------------------------------------------------
			var a4 = ((BevportListingPrice_24Unit - bevDeliveryFeeCase) - (stateCaseTax)) - ((BevportListingPrice_24Unit - bevDeliveryFeeCase) * 0.1);
			setCaseBPPrice(a4);
			//-----------------------------------------------------------------------------------------------------------------------------------

			// ----------------- 4 Pack - Bevport Price -----------------------------------------------------------------------------------------
			var a5 = ((BevportListingPrice_4Pack - bevDeliveryFeePack) - (statePackTax)) - ((BevportListingPrice_4Pack - bevDeliveryFeePack) * 0.1);
			setPackBPPrice(a5);
			//-----------------------------------------------------------------------------------------------------------------------------------

			// ----------------- Unit - Bevport price -------------------------------------------------------------------------------------------
			var a6 = ((BevportListingPrice_Unit - bevDeliveryFeeUnit) - (stateUnitTax)) - ((BevportListingPrice_Unit - bevDeliveryFeeUnit) * 0.1);
			setUnitBPPrice(a6);
			//-----------------------------------------------------------------------------------------------------------------------------------



			// FOB  - 24 unit case 
			var a7 = (a4 - cmcDeliveryFeeCaseTmp); // bevDeliveryFeeCase
			setCaseSRP(a7);
			//--------------------------------------------------------------------------------------------

			// FOB - 4 Pack
			var a8 = (a5 - cmcDeliveryFeePack); // bevDeliveryFeePack
			setPackSRP(a8);
			//--------------------------------------------------------------------------------------------

			// FOB - Unit
			var a9 = (a6 - cmcDeliveryFeeUnit); // bevDeliveryFeeUnit
			setUnitSRP(a9);
			//--------------------------------------------------------------------------------------------


		}
	

	}, [amountPer, type, amountIn, caseSize, packSize, cmcDeliveryFeeCase, cmcDeliveryFeePackEdit, stateCaseTax])
	useEffect(() => {
		if (!valueUpd) {
			/*if(amountPer == "unit"){
				setListPricemanual(parseFloat(unitListingPrice).toFixed(2))
			}
			if(amountPer == "pack"){
				setListPricemanual(parseFloat(packListingPrice).toFixed(2))
			}
			if(amountPer == "case"){
				setListPricemanual(parseFloat(caseListingPrice).toFixed(2))
			}*/
			/*IOUEOI*/
			setUnitPriceList(parseFloat(unitListingPrice).toFixed(2))
			setPackPriceList(parseFloat(packListingPrice).toFixed(2))
			setCasePriceList(parseFloat(caseListingPrice).toFixed(2))
		}

	}, [caseListingPrice, amountPer])
	useEffect(() => {
		if (amountPer === "case") {
			setAmountIn(amountInTmp)
		} else if (amountPer === "pack") {
			let div = caseSize / packSize
			setAmountIn(amountInTmp * div)
		} else if (amountPer === "unit") {
			setAmountIn(amountInTmp * caseSize)
		}
	}, [amountPer, amountInTmp])


	
	const handleAmountInFun = (e) => {
		setAmountIn(e.target.value)
		setAmountInTmp(e.target.value)
	}
	const handleFloatNumber = (e) => {
		if ((e.which != 46 || e.target.value.indexOf('.') != -1) && (e.which < 48 || e.which > 57)) {
			e.preventDefault();
		}
	}
	const priceConverter = (amount) => {
		return parseFloat(amount).toFixed(2)
	}
	const handleTypeOption = (e) => {
		setType(e.target.value)
		setValueUpd(false)
	}
	const handleAmountPer = (e) => {
		setAmountPer(e.target.value)
		setValueUpd(false)
	}

	const handleListingPriceFun = (e) => {
		setListPricemanual(e.target.value)
	}
	const handleCasePriceListFun = (e) => {
		let v = e.target.value;
		let getunitPrice = v / caseSize;
		setUnitPriceList(parseFloat(getunitPrice).toFixed(2))
		setPackPriceList(parseFloat(getunitPrice * packSize).toFixed(2))
		setCasePriceList(e.target.value)
		setUnitAmountError(false)
		setPackAmountError(false)
		setCaseAmountError(false)
	}
	const handlePackPriceListFun = (e) => {
		let v = e.target.value;
		let getunitPrice = v / packSize;
		setCasePriceList(parseFloat(caseSize * getunitPrice).toFixed(2))
		setUnitPriceList(parseFloat(getunitPrice).toFixed(2))
		setPackPriceList(e.target.value)
		setUnitAmountError(false)
		setPackAmountError(false)
		setCaseAmountError(false)
	}
	const handleUnitPriceListFun = (e) => {
		let v = e.target.value;
		setPackPriceList(parseFloat(packSize * v).toFixed(2))
		setCasePriceList(parseFloat(caseSize * v).toFixed(2))
		setUnitPriceList(e.target.value)
		setUnitAmountError(false)
		setPackAmountError(false)
		setCaseAmountError(false)
	}

	useEffect(() => {
		getPackSize();
		getCaseSize();

	}, [])

	const getPackSize = async () => {
		await axios.post(API_URL + "PackageSizeList?code=" + API_KEY, {}, config).then((res) => {
			if (res.data.responseCode == "200") {
				let packSizeOption = []
				let resData = res.data.responseData
				resData.forEach((da) => {
					packSizeOption.push({ value: da.value, label: da.value, id: da.id });

				}
				);
				setPackSizeList(packSizeOption);
			} else {

			}
		}).catch(function (error) {

		})
	}



	const getCaseSize = async () => {
		await axios.post(API_URL + "CaseSizeList?code=" + API_KEY, {}, config).then((res) => {
			if (res.data.responseCode == "200") {
				let caseSizeOption = [];
				let resData = res.data.responseData
				resData.forEach((da) => {
					caseSizeOption.push({ value: da.value, label: da.value, id: da.id });

				}
				);
				setCaseSizeList(caseSizeOption);
			} else {

			}
		}).catch(function (error) {

		})
	}

	const handlePackSize = (e) => {
		if (e.value === "Singles") {
			setPackSize(1);
		} else {
			let u = (e.value).split(" ")
			setPackSize(u[0]);
		}

		setPackSizeValue({ value: e.value, label: e.label })
	}

	const handleCaseSize = (e) => {
		let u = (e.value).split(" ")
		setCaseSize(u[0]);
		setCaseSizeValue({ value: e.value, label: e.label })
	}
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	const customStyles = {
		control: (provided, state) => ({
			...provided,
			borderColor: 'transparent',
			boxShadow: state.isFocused ? null : null,
			'&:hover': {
				border: '1px solid #b6b6af',
				boxShadow: null,
			},
			'&:focus': {
				border: '1px solid transparent',
				boxShadow: null,
			}
		})
	};
	const handleEstimateShippingFeeCase = (e) => {
		setCmcDeliveryFeePackEdit(true)
		setCmcDeliveryFeeUnitEdit(true)
		setCmcDeliveryFeeCase(e.target.value === '.'?"0.":e.target.value)
	}
	const handleEstimateShippingFeePack = (e) => {
		setCmcDeliveryFeePackEdit(false)
		setCmcDeliveryFeeUnitEdit(true)
		let v = (e.target.value)?(e.target.value === "."?"0":e.target.value):0;
		let getunitVal = v/packSize;
		setCmcDeliveryFeePack(e.target.value === '.'?"0.":e.target.value)
		setCmcDeliveryFeeCase(priceConverter(getunitVal*caseSize))
	}
	const handleEstimateShippingFeeUnit = (e) => {
		setCmcDeliveryFeePackEdit(true)
		setCmcDeliveryFeeUnitEdit(false)
		let v = (e.target.value)?(e.target.value === "."?"0":e.target.value):0;
		setCmcDeliveryFeeUnit(e.target.value === '.'?"0.":e.target.value)
		setCmcDeliveryFeeCase(priceConverter(v*caseSize))
	}
	const handleStateTaxCase = (e) => {
		setStateTaxPackEdit(true)
		setStateTaxUnitEdit(true)
		setStateCaseTax(e.target.value === '.'?"0.":e.target.value)
	}
	const handleStateTaxPack = (e) => {
		setStateTaxPackEdit(false)
		setStateTaxUnitEdit(true)
		let v = (e.target.value)?(e.target.value === "."?"0":e.target.value):0;
		let getunitVal = v/packSize;
		setStatePackTax(e.target.value === '.'?"0.":e.target.value)
		setStateCaseTax(priceConverter(getunitVal*caseSize))
	}
	const handleStateTaxUnit = (e) => {
		setStateTaxPackEdit(true)
		setStateTaxUnitEdit(false)
		let v = (e.target.value)?(e.target.value === "."?"0":e.target.value):0;
		setStateUnitTax(e.target.value === '.'?"0.":e.target.value)
		setStateCaseTax(priceConverter(v*caseSize))
	}

	// Cost Comparision  click  in about dropdown menu
	const costComparisonClick = () => {
		var profileLink = "";
		profileLink = "http://" + window.location.host + "/CostComparison";
		window.location.href = profileLink;
	};


	// Best Practices click dropdown menu main button 
	const bestPractices = () => {
		var profileLink = "";
		profileLink = "http://" + window.location.host + "/BestPractices";
		window.location.href = profileLink;
	};
	return (
		<>
			<div className="site-wrapper">
				<div className="main-wrapper">
					<Header />
					<section className="simplyfying Empowering" id="simplys">
						<div className="simplyfyingDiv">
							<div className="container-fluid">
								<div className="row upcontent">
									<div className="col-md-5 simplecontent aboutmainBg">
										<div className="simplecontentimg">
											<img className="img-responsive" src={aboutbgd} />
										</div>
									</div>
									<div className="col-md-7 manBgimg">
										<div className="manImg">
											<img className="img-responsive" src={resourcesbg} />
										</div>
									</div>
								</div>
								<div className="row">
									<div className="moveTopUp aboutheadphone">
										<div className="container">
											<div className="row main-contentprt">
												<div className="col-md-4">
													<div className="textSimply muddtext">
														<h1><span className="darkblacktext">The BevPort</span>Resources</h1>
														<p>Here we will provide our free users with useful information about licensing, state agencies, new laws, etc. For our members, here you will find links and information about how to promote your brand better, sales tools you can use to increase visibility, or marketing resources and vendor partners to help you build a sales team to help sell your products.</p>
													</div>
												</div>
												<div className="col-md-8">
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

						</div>
					</section>

					<section id="outerlinks" className="manageouter">
						<div className="outerlinksboundary">
							<div className="container">
								<div className="row">

							{/*	// ---  jesse dudley ------ commented out 5/3/2024 ------*/}


									{/*<div className="col-md-4">*/}
									{/*	<div className="linksoutdiv">*/}
									{/*		<Link to="http://www.myfloridalicense.com/DBPR/alcoholic-beverages-and-tobacco/" target="_blank">Liquor<br />licensing</Link>*/}
									{/*	</div>*/}
									{/*</div>*/}
									{/*<div className="col-md-4">*/}
									{/*	<div className="linksoutdiv">*/}
									{/*		<Link to="https://www.flsenate.gov/Laws/Statutes/2012/Chapter565/All" target="_blank">State<br />laws</Link>*/}
									{/*	</div>*/}
									{/*</div>*/}


									<div className="col-md-6">
										<div className="linksoutdiv">
											<Link onClick={bestPractices} target="_blank">Bevport <br />Best Practices</Link>
										</div>
									</div>

									<div className="col-md-6">
										<div className="linksoutdiv">
											<Link onClick={costComparisonClick} target="_blank">Bevport <br />Cost Comparison</Link>
										</div>
									</div>


								</div>
							</div>
						</div>
					</section>
					<section id="pricehoneWeb" className="priceCalculater">
						<div className="priceLivepage">
							<div className="container">

								<div className="row">
									<div className="col-md-12">
										<div className="InsertForms priceCalculator">
											<h3 className="card-heading topsubheading">BevPort Pricing Calculator <sup>©</sup></h3>
											<div className="row pricecalPara">
												<div className="col-md-12">
													<p>BevPort is proud to offer you our Proprietary Pricing Calculator to help you better calculate your brands best price.</p>
													<p>Remember, the margins are simply a guideline to average pricing, but actual  margins and pricing can change.</p>
													<p>As a member, our Upgraded Pricing Calculator offers more options and flexibility to really better understand your pricing.</p>
												</div>
											</div>

											<div className="row">
												<div className="col-md-6">
													<div className="mainpageFilter">
														<label className="">Case Size <span style={{ color: "red" }}>*</span></label>
														<Select className="selectTodesign"
															styles={customStyles}
															options={caseSizeList}
															onChange={handleCaseSize}
															value={caseSizeValue}
															closeMenuOnSelect={true}
															placeholder={<div className="italic-placeholder">Select Case Size</div>}
															isSearchable
														/>
													</div>
												</div>
												<div className="col-md-6">
													<div className="mainpageFilter">
														<label className="">Pack Size <span style={{ color: "red" }}>*</span></label>
														<Select className="selectTodesign"
															styles={customStyles}
															options={packSizeList}
															onChange={handlePackSize}
															value={packSizeValue}
															closeMenuOnSelect={true}
															placeholder={<div className="italic-placeholder">Select Pack Size</div>}
															isSearchable
														/>
													</div>
												</div>

											</div>
											<div className="row">
												<div className="col-md-6">


													<div className="form-group mb-1">
														<label className="" style={{ display: "block" }}>Calcuated By <span style={{ color: "red" }}>*</span></label>
														<label class="radio-inline cardSubtext">
															<input type="radio" name="calcBy" value="FOB" className="" onChange={handleTypeOption} checked={(type === "FOB" ? true : false)} />FOB


														</label>
														<label class="radio-inline cardSubtext">
															<input type="radio" name="calcBy" value="SRP" className="" onChange={handleTypeOption} checked={(type === "SRP" ? true : false)} />SRP
														</label>

													</div>
													<p className="cardSubtext">
														<b>Calculate by FOB:</b> Enter your FOB (Price to Distributor), we will break down your margins and show you what your PTC (Price to customer per unit, pack or case) will be after all the margins, taxes, and delivery fee.
													</p>
													<p className="cardSubtext">
														<b>Calculate by SRP: </b>Enter your SRP (Suggested Retail Price per unit, pack or case), we will break down your margins and show you want your FOB (Price to Distributor) will be after all the margins, taxes, and delivery fee.
													</p>
												</div>
												<div className="col-md-6">

													<div className="form-group amount-field">
														<label className="cardSubtext me-2">Amount Per</label>
														<label class="radio-inline cardSubtext">
															<input type="radio" name="amoutPer" value="unit" onChange={handleAmountPer} checked={(amountPer === "unit" ? true : false)} />Unit
														</label>
														<label class="radio-inline cardSubtext">
															<input type="radio" name="amoutPer" value="pack" onChange={handleAmountPer} checked={(amountPer === "pack" ? true : false)} />Pack
														</label>
														<label class="radio-inline cardSubtext">
															<input type="radio" name="amoutPer" value="case" onChange={handleAmountPer} checked={(amountPer === "case" ? true : false)} />Case
														</label>
													</div>
													<div className="form-group">
														<label className="">Amount in <span style={{ color: "red" }}>*</span></label>
														<input type="text" className={(amountError) ? "form-control error" : "form-control"} onChange={handleAmountInFun} onKeyPress={handleFloatNumber} value={amountInTmp} placeholder="Amount in" />
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-md-12">
													<div className="table-responsive">
														<table class="table table-bordered">
															<thead>
																<tr>
																	<th style={{ width: "400px" }}></th>
																	<th>{caseSize} Units Case</th>
																	<th>{packSize} Packs</th>
																	<th>Unit</th>

																</tr>
															</thead>
															<tbody>
																<tr>
																	<th>SRP<Tooltip
																		title={<span className="toolTipData">This is the final retail price to customers.</span>}
																		arrow
																		placement={"right-start"}
																		style={{ marginBottom: "100px" }}

																	><i className="customToolTip" style={{ marginBottom: "50px" }}> <HelpIcon /></i>

																	</Tooltip></th>
																	{
																		type === "FOB" ? <>
																			<td>${priceConverter(caseSRP)}</td>
																			<td>${priceConverter(packSRP)}</td>
																			<td>${priceConverter(unitSRP)}</td>
																		</> : <>
																			<td>${priceConverter(unitCasePrice)}</td>
																			<td>${priceConverter(packPrice)}</td>
																			<td>${priceConverter(unitPrice)}</td>
																		</>
																	}
																</tr>
																<tr>
																	<td>Average Retailer Margin<Tooltip
																		title={<span className="toolTipData">This is an average margin charged by retailers.</span>}
																		arrow
																		placement={"right-start"}
																		style={{ marginBottom: "100px" }}

																	><i className="customToolTip" style={{ marginBottom: "50px" }}> <HelpIcon /></i>

																	</Tooltip></td>
																	<td>{priceConverter(avMargin)}%</td>
																	<td>{priceConverter(avMargin)}%</td>
																	<td>{priceConverter(avMargin)}%</td>
																</tr>
																<tr>
																	<td>Bevport Listing Price <Tooltip
																		title={<span className="toolTipData">This is the price that Buyers will see on Bevport.</span>}
																		arrow
																		placement={"right-start"}
																		style={{ marginBottom: "100px" }}

																	><i className="customToolTip" style={{ marginBottom: "50px" }}> <HelpIcon /></i>

																	</Tooltip></td>
																	<td>${priceConverter(caseListingPrice)}</td>
																	<td>${priceConverter(packListingPrice)}</td>
																	<td>${priceConverter(unitListingPrice)}</td>
																</tr>
																<tr>
																	<td>Delivery Fee <Tooltip
																		title={<span className="toolTipData">Cost of delivery to retailers.</span>}
																		arrow
																		placement={"right-start"}
																		style={{ marginBottom: "100px" }}

																	><i className="customToolTip" style={{ marginBottom: "50px" }}> <HelpIcon /></i>

																	</Tooltip></td>
																	<td>${priceConverter(bevDeliveryFeeCase)}</td>
																	<td>${priceConverter(bevDeliveryFeePack)}</td>
																	<td>${priceConverter(bevDeliveryFeeUnit)}</td>
																</tr>
																
																<tr>
																	<td>Bevport Margin <Tooltip
																		title={<span className="toolTipData">Percentage for Bevport Distribution.</span>}
																		arrow
																		placement={"right-start"}
																		style={{ marginBottom: "100px" }}

																	><i className="customToolTip" style={{ marginBottom: "50px" }}> <HelpIcon /></i>

																	</Tooltip></td>
																	<td>{priceConverter(bpMargin)}%</td>
																	<td>{priceConverter(bpMargin)}%</td>
																	<td>{priceConverter(bpMargin)}%</td>
																</tr>
																<tr>
																	<td>Bevport Price <Tooltip
																		title={<span className="toolTipData">Price you are selling product to Bevport.</span>}
																		arrow
																		placement={"right-start"}
																		style={{ marginBottom: "100px" }}

																	><i className="customToolTip" style={{ marginBottom: "50px" }}> <HelpIcon /></i>

																	</Tooltip></td>
																	<td>${priceConverter(caseBPPrice)}</td>
																	<td>${priceConverter(packBPPrice)}</td>
																	<td>${priceConverter(unitBPPrice)}</td>
																</tr>
																<tr className="custom-dollor">
																	<td className="remove-dollar">Estimated Shipping Fee *<Tooltip
																		title={<span className="toolTipData"> Country Malt's Cost per case to pick up from your brewery to our warehouses.</span>}
																		arrow
																		placement={"right-start"}
																		style={{ marginBottom: "100px" }}

																	><i className="customToolTip" style={{ marginBottom: "50px" }}> <HelpIcon /></i>

																	</Tooltip></td>
																	{/*<td>${priceConverter(cmcDeliveryFeeCase)}</td>
																	<td>${priceConverter(cmcDeliveryFeePack)}</td>
																	<td>${priceConverter(cmcDeliveryFeeUnit)}</td>*/}
																	<td><input type="text" className={"form-control"} placeholder="Case Estimated Shipping Fee" value={cmcDeliveryFeeCase} onChange={handleEstimateShippingFeeCase} onKeyPress={handleFloatNumber} /></td>
																	<td><input type="text" className={"form-control"} placeholder="Pack Estimated Shipping Fee" value={cmcDeliveryFeePack} onChange={handleEstimateShippingFeePack} onKeyPress={handleFloatNumber} /></td>
																	<td><input type="text" className={"form-control"} placeholder="Unit Estimated Shipping Fee" value={cmcDeliveryFeeUnit} onChange={handleEstimateShippingFeeUnit} onKeyPress={handleFloatNumber}/></td>
																</tr>
																
																
																<tr className="custom-dollor">
																	<td className="remove-dollar">Estimated State Tax <Tooltip
																		title={<span className="toolTipData">State tax for Alcoholic beverages.</span>}
																		arrow
																		placement={"right-start"}
																		style={{ marginBottom: "100px" }}

																	><i className="customToolTip" style={{ marginBottom: "50px" }}> <HelpIcon /></i>

																	</Tooltip></td>
																	{/*<td>${priceConverter(stateCaseTax)}</td>
																	<td>${priceConverter(statePackTax)}</td>
																	<td>${priceConverter(stateUnitTax)}</td>*/}
																	<td><input type="text" className={"form-control"} onChange={handleStateTaxCase} placeholder="Case Estimated State Tax" value={stateCaseTax} onKeyPress={handleFloatNumber}/></td>
																	<td><input type="text" className={"form-control"}  placeholder="Pack Estimated State Tax" value={statePackTax} onChange={handleStateTaxPack} onKeyPress={handleFloatNumber}/></td>
																	<td><input type="text" className={"form-control"}  placeholder="Unit Estimated State Tax" value={stateUnitTax} onChange={handleStateTaxUnit} onKeyPress={handleFloatNumber}/></td>
																</tr>
																<tr>
																	<th>FOB <Tooltip
																		title={<span className="toolTipData">This is your base cost to Distributor.</span>}
																		arrow
																		placement={"right-start"}
																		style={{ marginBottom: "100px" }}

																	><i className="customToolTip" style={{ marginBottom: "50px" }}> <HelpIcon /></i>

																	</Tooltip></th>
																	{
																		type === "SRP" ? <>
																			<td>${priceConverter(caseSRP)}</td>
																			<td>${priceConverter(packSRP)}</td>
																			<td>${priceConverter(unitSRP)}</td>
																		</> : <>
																			<td>${priceConverter(unitCasePrice)}</td>
																			<td>${priceConverter(packPrice)}</td>
																			<td>${priceConverter(unitPrice)}</td>
																		</>
																	}


																</tr>
																{/*<tr className="custom-dollor">
										<th>Suggested BevPort Listing Price. Edit and Save </th>
										<td><input type="text" className={(caseAmountError) ? "form-control error" : "form-control"} value={casePriceList} onChange={handleCasePriceListFun} onKeyPress={handleFloatNumber} placeholder="Case BevPort Listing Price" /></td>
										<td><input type="text" className={(packAmountError) ? "form-control error" : "form-control"} value={packPriceList} onChange={handlePackPriceListFun} onKeyPress={handleFloatNumber} placeholder="Pack BevPort Listing Price" /></td>
										<td><input type="text" className={(unitAmountError) ? "form-control error" : "form-control"} value={unitPriceList} onChange={handleUnitPriceListFun} onKeyPress={handleFloatNumber} placeholder="Unit BevPort Listing Price" /></td>

									</tr>*/}
															</tbody>
														</table>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<Footer />
				</div>
			</div>


		</>
	)
}